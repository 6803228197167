import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import ExoAvatar from "../../components/exo/ExoAvatar";
import { renderHours } from "./worktime";
import InterestsIcon from "@mui/icons-material/Interests";
import { useTranslation } from "react-i18next";
import { useTimeTracking } from "../../components/time-tracking/AppTimeProvider";

const ProjectTimeCard = ({
  project,
  onSelect,
  onDeselect,
  selected,
  onClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { secondsIn } = useTimeTracking();

  function handleClick() {
    if (onClick) onClick(project);
  }

  return (
    <Box
      className=" w-60 rounded-xl backdrop-blur-lg"
      sx={{
        backgroundColor: colors.glass,
        minWidth: "15rem",
      }}
      onClick={handleClick}
    >
      <Box
        className="w-full p-4 rounded-xl h-full"
        sx={{
          borderColor: colors.glass,
          borderWidth: "1px",
          ":hover": onClick && {
            borderColor: colors.success,
            backgroundColor: colors.success + "30",
            cursor: "pointer",
          },
        }}
      >
        <Box className="flex justify-center items-center py-4">
          {project.clientType === "company" && (
            <ExoAvatar
              name={project.client.name}
              picture={project.client.logo && project.client.logo.thumbnailUrl}
              type={project.clientType}
              size="80px"
            />
          )}
          {project.clientType === "contact" && (
            <ExoAvatar
              name={project.client.firstName + " " + project.client.lastName}
              type={project.clientType}
              size="80px"
            />
          )}
          {project.clientType === null && (
            <ExoAvatar type={null} icon={<InterestsIcon />} />
          )}
        </Box>
        <Box>
          <Typography
            variant="h5"
            className=" text-center h-20 flex justify-center items-center flex-col"
          >
            {project.activeWorkPackage ? (
              <>
                {project.activeWorkPackage.title}
                <Typography variant="h6" sx={{ color: colors.primary[600] }}>
                  {project.name}
                </Typography>
              </>
            ) : (
              project.name
            )}
          </Typography>
        </Box>
        <Typography className=" text-center text-4xl mb-4" variant="h2">
          {project.activeWorkPackage
            ? renderHours(
                project.activeWorkPackage.totalTimeWorkedSeconds / 60 +
                  Math.round(secondsIn / 60)
              )
            : renderHours(project.timeTotal + Math.round(secondsIn / 60))}{" "}
          h
        </Typography>
        <Box
          className="w-full flex flex-col gap-2 overflow-y-auto px-2 py-2"
          sx={{
            maxHeight: "150px",
          }}
        >
          {selected && (
            <Button
              onClick={onDeselect}
              className=" w-full text-xl"
              variant="contained"
              color="error"
            >
              {t("Clock Out")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectTimeCard;
