import {
  Box,
  useTheme,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { ProjectUserStack } from "../ProjectUserStack";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import MediaPreview from "../../explorer/MediaPreview";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_DELETE, DBRequest, useConfig } from "../../../../api/api";
import { SettingsPopover } from "../SettingsPopover";
import EditIcon from "@mui/icons-material/Edit";
import { ProjectAddElementButton } from "../ProjectAddElementButton";
import { WPEForm } from "../work-package-elements/WPEForm";
import { WPE } from "../work-package-elements/WPE";
import { useState } from "react";
import ExoDialog from "../../ExoDialog";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useTimeTracking } from "../../../time-tracking/AppTimeProvider";
import StopIcon from "@mui/icons-material/Stop";
import { EstimatedTime } from "./EstimatedTime";
import SmallProgressBar from "../../../special/SmallProgressBar";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

export const ProjectWorkPackage = ({
  project,
  workPackage,
  onChange,
  onDelete,
  editmode,
  onEdit,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { clockIn, clockOut, activeProject, secondsIn } = useTimeTracking();

  const [openNewSE, setOpenNewSE] = useState(false);

  const settings = [
    {
      label: t("Edit"),
      Icon: EditIcon,
      onClick: () => onEdit(workPackage),
    },
    {
      label: t("Delete"),
      Icon: DeleteIcon,
      onClick: handleDelete,
      color: "error",
    },
  ];

  function handleDelete() {
    DBRequest({
      config,
      path: `work-packages/${workPackage.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(workPackage),
    });
  }

  function handleNewWPE(newElement) {
    const newElements = [...workPackage.elements, newElement];
    const updatedWorkPackage = { ...workPackage, elements: newElements };
    onChange(updatedWorkPackage);
  }

  function handleWPEDelete(elementToDel) {
    const newElements = workPackage.elements.filter(
      (obj) => obj.id !== elementToDel.id
    );
    onChange({ ...workPackage, elements: newElements });
  }

  const activeWorkpackage =
    activeProject &&
    activeProject.activeWorkPackage &&
    activeProject.activeWorkPackage.id === workPackage.id
      ? {
          ...activeProject.activeWorkPackage,
          totalTimeWorkedSeconds: secondsIn
            ? activeProject.activeWorkPackage.totalTimeWorkedSeconds + secondsIn
            : activeProject.activeWorkPackage.totalTimeWorkedSeconds,
        }
      : null;

  const workPercentage = activeWorkpackage
    ? activeWorkpackage.totalTimeWorkedSeconds / activeWorkpackage.estimatedTime
    : workPackage.totalTimeWorkedSeconds / workPackage.estimatedTime;

  function handleClockOut() {
    clockOut();
    onChange({
      ...workPackage,
      totalTimeWorkedSeconds: activeWorkpackage.totalTimeWorkedSeconds,
    });
  }

  return (
    <Box
      className="p-4 relative rounded-lg backdrop-blur-lg flex flex-col justify-between"
      sx={{ bgcolor: colors.glass }}
    >
      <Box>
        <Box className="flex justify-between">
          <Typography variant="h3" className="pb-2 flex items-center gap-4">
            {workPackage.icon && (
              <MediaPreview
                type="ICON"
                src={workPackage.icon.temporaryUrl}
                className="w-8 h-8"
              />
            )}
            {workPackage.title}
          </Typography>
          {!editmode &&
            Boolean(workPackage.isTimeTrackable) &&
            workPackage.allowTimeTracking &&
            (activeWorkpackage ? (
              <Button
                onClick={handleClockOut}
                sx={{ position: "absolute" }}
                className="top-1 right-1"
                variant="outlined"
                color="error"
              >
                <StopIcon />
              </Button>
            ) : (
              <Button
                onClick={() => clockIn(project, workPackage)}
                sx={{ position: "absolute" }}
                className="top-1 right-1"
                variant="outlined"
                color="success"
              >
                <PlayArrowIcon />
              </Button>
            ))}
          {editmode && <SettingsPopover settings={settings} />}
        </Box>
        <Box className="pb-4">
          <Box className="flex flex-col gap-1">
            {workPackage.elements &&
              workPackage.elements.map((element) => (
                <WPE
                  key={element.id}
                  element={element}
                  mode="preview"
                  editmode={editmode}
                  onDelete={handleWPEDelete}
                />
              ))}
          </Box>
          {editmode && (
            <>
              <ExoDialog
                limitWidth
                open={openNewSE}
                className="overflow-y-auto"
              >
                <WPEForm
                  workPackage={workPackage}
                  onCreate={handleNewWPE}
                  onClose={() => setOpenNewSE(false)}
                />
              </ExoDialog>
              <ProjectAddElementButton onClick={() => setOpenNewSE(true)} />
            </>
          )}
        </Box>
      </Box>

      <Box className="flex flex-col">
        {workPackage.estimatedTime && (
          <>
            <Divider />
            <Box className="mx-auto p-4 flex items-center gap-5">
              <Box sx={{ maxWidth: "80px" }}>
                <CircularProgressbarWithChildren
                  value={workPercentage}
                  maxValue={1}
                  styles={{
                    path: {
                      stroke:
                        workPercentage < 1
                          ? colors.primary[500]
                          : colors.redAccent[500],
                    },
                    trail: {
                      stroke: "rgba(0, 0, 0, 0.2)",
                      strokeLinecap: "butt",
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                    },
                  }}
                >
                  <Typography variant="h4">
                    {Math.round(workPercentage * 100)}%
                  </Typography>
                </CircularProgressbarWithChildren>
              </Box>
              <Box className="flex flex-col items-center">
                <Typography variant="h6" className="pt-2 uppercase">
                  {t("Worked")}
                </Typography>
                <Typography variant="h4">
                  {renderHours(
                    (activeWorkpackage
                      ? activeWorkpackage.totalTimeWorkedSeconds
                      : workPackage.totalTimeWorkedSeconds) / 60
                  )}
                  h
                </Typography>
                <Typography variant="h4"></Typography>
              </Box>
            </Box>
          </>
        )}
        <Divider />
        <Box className="flex justify-between items-start">
          <EstimatedTime workPackage={workPackage} />

          <ProjectUserStack
            editmode={editmode}
            users={workPackage.users}
            workPackage={workPackage}
            onChange={onChange}
            projectId={project.id}
          />
        </Box>
      </Box>
    </Box>
  );
};
