import { Box, Dialog, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../global/theme/tokens";
import FormHelperText from "../FormHelperText";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExoExplorer from "../../exo/ExoExplorer";
import MediaPreview from "../../exo/explorer/MediaPreview";

const TypeImages = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);

  function handleClickImage() {
    setOpen(!open);
  }

  const handleSelect = (images) => {
    updateValidationOnChange(field.key, images);
    handleClickImage();
  };

  const handleReset = () => {
    updateValidationOnChange(field.key, []);
  };

  return (
    <Box className="w-full">
      <Box className="flex gap-2 w-full">
        {dataCollection[field.key] && (
          <>
            {dataCollection[field.key].map((image) => (
              <MediaPreview
                src={image.temporaryUrl}
                onlyIcons={false}
                size="53px"
              />
            ))}
          </>
        )}

        <Box
          onClick={handleClickImage}
          className="w-full flex justify-start items-center px-3 cursor-pointer"
          sx={{
            height: "53px",
            borderRadius: "10px",
            borderWidth: "0px",
            backgroundColor: colors.bgInput,
            borderColor: colors.primary[300],
            ":hover": {
              borderColor: colors.primary[900],
              cursor: "pointer",
            },
          }}
        >
          {field.label} {field.maxItems && `(${field.maxItems} MAX)`}
        </Box>
      </Box>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: "80vw", height: "80vh" }}>
          <ExoExplorer
            onSelect={handleSelect}
            multiSelect
            preSelected={dataCollection[field.key]}
            maxItems={field.maxItems}
            maxSizeKB={field.maxSizeKB}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default TypeImages;
