import { Box, Typography } from "@mui/material";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import { useTranslation } from "react-i18next";

export const EstimatedTime = ({ workPackage }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h6" className="pt-2 uppercase">
        {workPackage.estimatedTime ? t("Planed") : t("Worked")}
      </Typography>
      <Typography variant="h4">
        {renderHours(
          workPackage.estimatedTime / 60 ||
            workPackage.totalTimeWorkedSeconds / 3600
        )}
        h
      </Typography>
      <Typography variant="h6" className="monospace">
        {workPackage.nr}
      </Typography>
    </Box>
  );
};
