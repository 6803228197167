import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useState } from "react";
import { PositionNumber } from "./collapse-modules/PositionNumber";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../../api/api";
import CalculateIcon from "@mui/icons-material/Calculate";
import { numberToString } from "../../special/numberConverter";

export const PositionDiscount = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const [data, setData] = useState({});
  const [newDiscount, setNewDiscount] = useState(null);

  function handleChange(e, key) {
    if (e && e.target && e.target.value)
      setData({ ...data, [key]: e.target.value });
  }

  function calcNewPrice() {
    if (data.discountBase && data.discountRate)
      setNewDiscount(data.discountBase * ((100 - data.discountRate) / 100));
  }

  return (
    <Box
      className="w-full fixed bottom-0 left-0 p-4 rounded-t-lg backdrop-blur-lg"
      sx={{
        bgcolor: colors.bgInput,
      }}
    >
      <Typography className="pt-3">
        {t("Calculation of sales price")}
      </Typography>
      <Box className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
        <PositionNumber
          data={data.discountBase || 0}
          label={"Discount base"}
          onFocus={() => {}}
          onChange={(e) => handleChange(e, "discountBase")}
          unit={config ? config.currency.currencySymbol : ""}
        />
        <PositionNumber
          data={data.discountRate || 0}
          label={"Discount Rate Position"}
          onFocus={() => {}}
          onChange={(e) => handleChange(e, "discountRate")}
          unit={"%"}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={calcNewPrice}
          className="flex gap-4"
        >
          <CalculateIcon />
          {`${
            newDiscount ? numberToString(newDiscount, 2) : numberToString(0, 2)
          } ${config ? config.currency.currencySymbol : ""} ${t("transfer")}`}
        </Button>
      </Box>
    </Box>
  );
};
