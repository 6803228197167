import { Box, Dialog, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../global/theme/tokens";
import FormHelperText from "../FormHelperText";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExoExplorer from "../../exo/ExoExplorer";

const TypeImage = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);
  const [img, setImg] = useState("");

  function handleClickImage() {
    setOpen(!open);
  }

  const handleSelect = (image) => {
    updateValidationOnChange(field.key, image);
    setImg(image.temporaryUrl);
    handleClickImage();
  };

  const handleReset = () => {
    updateValidationOnChange(field.key, {});
    console.log("reset");
  };

  return (
    <Box>
      <Box className="flex gap-2">
        <Box className="relative  group">
          <Box
            sx={{
              width: "53px",
              height: "53px",
              borderRadius: "10px",
              borderWidth: "0px",
              borderColor: colors.primary[300],
              backgroundColor: colors.bgInput,
              backgroundImage: `url(${
                dataCollection[field.key]
                  ? dataCollection[field.key].temporaryUrl
                  : ""
              })`,
              backgroundSize: "cover",
            }}
          />

          {dataCollection[field.key] &&
            dataCollection[field.key].temporaryUrl && (
              <Box
                onClick={handleReset}
                className="absolute opacity-0 group-hover:opacity-100 w-full h-full top-0 left-0 z-10 cursor-pointer"
                bgcolor={colors.glass}
                sx={{
                  borderRadius: "10px",
                }}
              >
                <RemoveCircleOutlineIcon
                  sx={{ position: "absolute" }}
                  className=" top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 "
                  color="error"
                />
              </Box>
            )}
        </Box>

        <Box
          onClick={handleClickImage}
          className="w-full flex justify-start items-center px-3"
          sx={{
            height: "53px",
            borderRadius: "10px",
            borderWidth: "0px",
            backgroundColor: colors.bgInput,
            borderColor: colors.primary[300],
            ":hover": {
              borderColor: colors.primary[900],
              cursor: "pointer",
            },
          }}
        >
          {field.label}
        </Box>
      </Box>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: "80vw", height: "80vh" }}>
          <ExoExplorer onSelect={handleSelect} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default TypeImage;
